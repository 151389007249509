<template>
  <el-dialog
    title="进度查看"
    :close-on-click-modal="false"
    :visible.sync="visible">
    <el-form :model="dataForm" :rules="dataRule" ref="dataForm" @keyup.enter.native="dataFormSubmit()" label-width="100px">
    <el-form-item label="指标名称">
      <label key="dataForm.pName">{{dataForm.pName}}</label>
    </el-form-item>
    <el-row>
      <el-col :span="6">
        <el-form-item label="新增用户数">
        <label key="dataForm.addUserNumber">{{dataForm.addUserNumber}}</label>
      </el-form-item>
      </el-col>
      <el-col :span="6">
        <el-form-item label="增值收入">
        <label key="dataForm.valueAddedIncome">{{dataForm.valueAddedIncome}}</label>
      </el-form-item>
      </el-col>
      <el-col :span="6">
        <el-form-item label="大众业务收入">
        <label key="dataForm.publicBusinessIncome">{{dataForm.publicBusinessIncome}}</label>
      </el-form-item>
      </el-col>
      <el-col :span="6">
        <el-form-item label="5G用户发展用户">
        <label key="dataForm.developmentUser">{{dataForm.developmentUser}}</label>
      </el-form-item>
      </el-col>
    </el-row>
    <el-form-item label="新增用户数完成进度">
      <el-progress :text-inside="true" :stroke-width="26" :percentage="dataForm.addPercent"></el-progress>
    </el-form-item>
    <el-form-item label="增值收入完成进度">
      <el-progress :text-inside="true" :stroke-width="26" :percentage="dataForm.accretionPercent"></el-progress>
    </el-form-item>
    <el-form-item label="大众业务收入完成进度">
      <el-progress :text-inside="true" :stroke-width="26" :percentage="dataForm.busPercent"></el-progress>
    </el-form-item>
    <el-form-item label="5G用户发展用户完成进度">
      <el-progress :text-inside="true" :stroke-width="26" :percentage="dataForm.fivePercent"></el-progress>
    </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer" v-loading="isOnSubmit">
      <el-button @click="visible = false">返回</el-button>
    </span>
  </el-dialog>
</template>

<script>
import {conversion} from '@/api/dataflow/olxpoktrack.js'
  export default {
    data () {
      return {
        visible: false,
        isOnSubmit: false,
        dataForm: {
          id: null,
          pName: '',
          addUserNumber: '',
          valueAddedIncome: '',
          publicBusinessIncome: '',
          developmentUser:'',
          addPercent:'',
          accretionPercent:'',
          busPercent:'',
          fivePercent:''
        },
      }
    },
    methods: {
      init (areaCode,type,month,addUserNumber,valueAddedIncome,publicBusinessIncome,developmentUser) {
        this.visible = true
        this.isOnSubmit = false
        this.dataForm.pName = ""
        this.dataForm.addUserNumber = ""
        this.dataForm.valueAddedIncome = ""
        this.dataForm.publicBusinessIncome = ""
        this.dataForm.developmentUser = ""
        this.$nextTick(() => {
          this.$refs['dataForm'].resetFields()
          if (areaCode) {
            conversion({
              areaCode:areaCode,
              month:month,
              type:type,
              addUserNumber:addUserNumber,
              valueAddedIncome:valueAddedIncome,
              publicBusinessIncome:publicBusinessIncome,
              developmentUser:developmentUser
          }).then(({data}) => {
              if (data && data.code === 0) {
                this.dataForm.pName = data.data.orkName
                this.dataForm.addUserNumber = data.data.addUserNumber
                this.dataForm.valueAddedIncome = data.data.valueAddedIncome
                this.dataForm.publicBusinessIncome = data.data.publicBusinessIncome
                this.dataForm.developmentUser = data.data.developmentUser
                this.dataForm.addPercent = data.data.addPercent
                this.dataForm.accretionPercent = data.data.accretionPercent
                this.dataForm.busPercent = data.data.busPercent
                this.dataForm.fivePercent = data.data.fivePercent
              }
            }).catch((err) => {
              this.$message.error(err)
            })
          }
        })
      },
    }
  }
</script>
